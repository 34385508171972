.videoContainer {
    margin: 50px;
    background-color: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding: 30px;
    border-radius: 10px;
    height: calc(100vh - 200px);
    width: calc(100vw - 200px);
    overflow-y: auto;
}

.video {
    width: calc((100vw - 260px)/2);
    height: calc((100vw - 260px)/2/16*9)
}