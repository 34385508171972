.videoWrapper {
    margin: 50px;
    background-color: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding: 30px;
    border-radius: 10px;
    height: calc(100vh - 200px);
    width: calc(100vw - 200px);
    overflow-y: auto;
}

.stackKeywords {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: calc(50% - 15px);
    border-right: 5px solid rgba(0, 0, 0, 0.16);
    padding-right: 5px;
    margin-right: 5px;
}

.thumbnail {
    width: 50%;
}

.videoResult {
    display: flex;
    justify-content: space-between;
    padding: 10px 0 0 0;
    cursor: pointer;
    text-decoration: none;
    color: black;
}

.subjectColored {
    position: relative;
    width: auto;
    border-radius: 20px;
    border: none;
    opacity: 0.6;
    transition: linear 0.4s;
    cursor: pointer;
    padding: 5px;
    margin: 4px;
    top: 0;
}

.subjectColored:hover {
    opacity: 1;
    top: 5px;
}