* {
  font-family: Roboto, Verdana;
}

body {
  padding: 0;
  margin: 0;
}

.navbarList {
  width: calc(100% - 100px);
  position: absolute;
  left: 0;
  top: 0;
  list-style-type: none;
  margin: 0;
  padding: 0 50px;
  overflow: hidden;
  background-color: white;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbarSides {
  height: 100%;
  display: flex;
  align-items: center;
}

.navbarLinks {
  display: block;
  color: #333;
  text-align: center;
  padding: 0 16px;
  text-decoration: none;
  font-family: Roboto;
  font-weight: 800;
}

.loginBoxHighlight > a {
  padding: 10px 5px;
  border-radius: 10px;
  background: -webkit-linear-gradient(to right, #56ccf2, #2f80ed);
  background: linear-gradient(to right, #56ccf2, #2f80ed);    color: #eee;
  width: 80%;
  margin-right: 20px;
}



/*   Standardized Classes   */
.componentContainer {
position: absolute;
top: 50px;
width: 100vw;
height: calc(100vh - 50px);
background: -webkit-linear-gradient(to right, #56ccf2, #2f80ed);
background: linear-gradient(to right, #56ccf2, #2f80ed);
display: flex;
justify-content: center;
}

.defaultInput {
padding: 10px;
border: none;
border-radius: 10px;
box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;    
font-family: Roboto;
}

.defaultButton {
padding: 10px 10px;
border-radius: 10px;
background: -webkit-linear-gradient(to right, #56ccf2, #2f80ed);
background: linear-gradient(to right, #56ccf2, #2f80ed);    
color: #eee;
border: none;
font-weight: 400;
cursor: pointer;
box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
transition: all 0.3s ease 0s;
}

.spaceUnderInputs {
margin: 0 0 20px 0;
}

.spaceAroundInputs {
margin: 0 10px;
}

.defaultButton:hover {
box-shadow: 0px 5px 5px #56ccf2;
}

.defaultButton:active {
transform: translateY(5px);
}

.defaultButton:disabled, .defaultButton[disabled] {
opacity: 0.4;
cursor: not-allowed;
}

.submissionOuterContainer {
margin: 50px;
background-color: #FFFFFF;
box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
padding: 30px;
border-radius: 10px;
height: calc(100vh - 200px);
display: flex;
flex-direction: column;
align-items: center;
width: 400px;
overflow-y: auto;
}

.componentContainer {
  background: -webkit-linear-gradient(to right, #56ccf2, #2f80ed);
  background: linear-gradient(to right, #56ccf2, #2f80ed);    
  width: 100%;
}

.profileText {
  color: blue;
}

.logout {
  margin-left: 20px;
}

.logoutHover {
  display: flex;
  align-items: center;
}